
const BoatPage = () => (
    <div className="container mainContent">
        <center>
                <h3  className="page-head">The Boat Page!</h3>
            <div>
                <img className="main-img" src="../images/Big-Ts-Boat.jpg" alt="Big Ts Boat"></img>
                <p  className="img-txt">Big T’s is a 29 ft World Cat.  She is a very safe and seaworthy boat and a great offshore fishing platform.</p>
            </div>
        </center>
    </div>
);
  
export default BoatPage;